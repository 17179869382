import React, { Component } from 'react'
import L from 'leaflet'
import SearchBox from '../searchbox/SearchBox'
import  utils from "../../utils";

import * as dumaData from '../../data/Duma.json'
import Legend from '../legend/Legend';


const openStreetMap = L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
})

const googleSatellite = L.tileLayer('https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}', {
    attribution: `Map data &amp;copy ${Date.now()} Google Google</a>`
})

export class Map extends Component {
    constructor(props) {
        super(props);

        this.state = {
            center: [-20.00819355637375, 31.60399675369263],
            zoom: 17,
            mapData: [],
            index: [],
            searchResults: [],
            featureProperty: 'pkuid',
            feature: {
                name: 'pkuid',
                type: 'range',
                categoryColors: []
            }
        }

        this.categoryColors = {
            'pkuid': {
                color: '#BD0026',
                title: 'pkuid'
            }
        }
    }

    render() {
        return (
            <div id='map'>
                <SearchBox />
                <Legend
                    featureCategories={this.state.featureCategories}
                    featureProperty={this.state.featureProperty}
                    setFeatureProperty={(f) => this.setState({ featureProperty: f })}
                    categoryColors={this.state.feature.categoryColors}
                />
            </div>
        )
    }

    componentDidMount() {

        this.map = L.map('map', {
            center: this.state.center,
            zoom: 17,
            zoomControl: false,
            layers: [
                openStreetMap
            ]
        });

        this.map.on({
            zoom: (e) => {
                this.setState({ zoom: e.target.getZoom() })
            }
        })

        this.schemeLabels = L.layerGroup();
        this.schemeLabels.addTo(this.map)

        this.schemes = L.layerGroup();
        this.schemes.addTo(this.map)


        var baseMaps = {
            "Map": openStreetMap,
            "Satellite": googleSatellite
        };

        var overlayMaps = {
            "Schemes": this.schemes,
            "Labels":this.schemeLabels
        };

        L.control.layers(baseMaps, overlayMaps).addTo(this.map);


        const properties = Object.keys(dumaData.features[0].properties)
        const {featureType, categoryColors} = utils.generateCategoryColors(dumaData, this.state.featureProperty)
      
        this.setState(state => ({
            mapData: state.mapData.concat(dumaData),
            featureCategories: properties,
            feature: {
                name: this.state.featureProperty,
                type:featureType,
                categoryColors
            }
        }))
    }

    componentDidUpdate(){
        this.map.eachLayer(layer=>{
            if(layer.options.pane === "overlayPane"){
                this.map.removeLayer(layer)
            }
        })

        this.categoryColors = utils.generateCategoryColors(dumaData, this.state.featureProperty).categoryColors

        

        this.state.mapData.map(data => {
            var layer = this.geojson = L.geoJSON(dumaData.features,{
                style: this.style,
                onEachFeature: this.onEachFeature
            })

            if(this.state.zoom > 15){
                layer.addTo(this.schemes)
                layer.addTo(this.map)
            }

            L.marker(layer.getBounds().getCenter(), {
                icon: L.divIcon({
                    html: "Duma",
                    className: 'scheme-label'
                })
            }).addTo(this.schemeLabels);

            
    
            /* var info = this.info = L.control();
    
            info.onAdd = function (map) {
                this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
                this.update();
                return this._div;
            };
    
            // method that we will use to update the control based on feature properties passed
            info.update = function (props) {
                var html = [];
                props && html.push([
                    '<table>',
                    `< tr > <th> Property:</th> <td>${props.pkuid}</td</tr> `,
                    '</table>'
                ])
                this._div.innerHTML = '<h4> Property Details</h4>' +  (props ?
                    html.join(" ")
                    : 'Hover over a property');
            };
    
            info.addTo(this.map); */
        })
    }

    highlightFeature = (e) => {
        var layer = e.target;

        layer.setStyle({
            weight: 5,
            color: '#666',
            dashArray: '',
            fillOpacity: 0.7
        });
    
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }

        //this.info.update(layer.feature.properties);
    }

    style = (feature) => {
      
        
        return {
            fillColor: this.getColor(feature.properties[this.state.featureProperty]),
            weight: 2,
            opacity: 1,
            color: 'white',
            dashArray: '0',
            fillOpacity: 0.7
        };
    }

    zoomToFeature = (e) => {
        this.map.fitBounds(e.target.getBounds());
    }

    onEachFeature = (feature, layer) => {
        L.marker(layer.getBounds().getCenter(), {
            icon: L.divIcon({
                html: feature.properties['pkuid'],
                className: 'property-label'
            })
        }).addTo(this.schemeLabels);

        layer.on({
            mouseover: this.highlightFeature,
            mouseout: this.resetHighlight,
            click: this.zoomToFeature
        });
    }

    resetHighlight = (e) => {
        this.geojson.resetStyle(e.target);
        
    }

    getColor = (d) => {
        if(this.state.feature.type === 'range') {
            return utils.getColor(d,this.state.feature.categoryColors)
        }

        return d > 1000 ? '#800026' :
               d > 500  ? '#BD0026' :
               d > 200  ? '#E31A1C' :
               d > 100  ? '#FC4E2A' :
               d > 50   ? '#FD8D3C' :
               d > 20   ? '#FEB24C' :
               d > 10   ? '#FED976' :
                          '#FFEDA0';
    }
}



export default Map

