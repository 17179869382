import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '10px 10px',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        position:'relative',
        zIndex: 1000
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    hidden: {
        display:'none'
    },
}));



export default function SearchBoxForm(props) {
    const classes = useStyles();
    const [search, setSearch] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        props.searchFeature(search)
     }

     const onChange = (e) => {
        setSearch(e.target.value)
     }

    return (
        <Paper component="form" className={classes.root} >
            <IconButton className={classes.hidden} aria-label="menu" onClick={props.openDrawer}>
                <MenuIcon />
            </IconButton>
            <InputBase
                className={classes.input}
                placeholder="Search Schemes"
                inputProps={{ 'aria-label': 'search schemes' }}
                value ={search}
                onChange={onChange}
                autoFocus={true}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={onSubmit} >
                <SearchIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton color="primary" className={classes.iconButton} aria-label="directions">
                <DirectionsIcon />
            </IconButton>
        </Paper>
    );
}