import React from 'react'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export default function SearchListItem(props) {
    const {data, zoomToFeature} = props
    return (
        <ListItem button alignItems="flex-start" onClick={zoomToFeature.bind(this,data.layer,[400,0])}>
            <ListItemText
                primary={data.key}
                secondary="Duma"
            />
        </ListItem>
    )
}
