import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { NONAME } from 'dns'

const useStyles = makeStyles(theme => ({
    legend: {
        padding: '6px 8px',
        font: '14px/16px Arial, Helvetica, sans-serif',
        lineHeight: "20px",
        background: 'white',
        background: 'rgba(255,255,255,0.8)',
        boxRshadow: '0 0 15px rgba(0,0,0,0.2)',
        borderRadius: "5px"
    },
    select: {
        '.MuiSelect-select:focus': {
            background: 'none'
        }
    },
    title: {
        margin: " 0 0 5px",
        color: '#777'
    },
    bottom: {
        bottom: 0,
        marginBottom: '10px'
    },
    bottomright: {
        position: "absolute",
        zIndex: 1000,
        bottom: 0,
        right: 0,
        marginRight: "10px",
        marginBottom: "25px",
    },
    legendI: {
        width: "18px",
        height: '18px',
        float: 'left',
        marginRight: '8px',
        opacity: 0.7
    }
}))

export default function Legend(props) {
    const classes = useStyles()
    const { featureCategories, featureProperty, setFeatureProperty, categoryColors} = props

    return (
        <React.Fragment>
            {featureCategories && (
                <div className={classes.bottomright}>
                    <div className={classes.legend}>
                        <div>

                            <Select className={classes.select} disableUnderline id="select" value={featureProperty}>
                                {featureCategories.map(cat => (
                                    <MenuItem key={cat} value={cat}>{cat}</MenuItem>
                                ))}
                            </Select>
                        </div>

                        {categoryColors.map(category => (
                            <span key={category.color}>
                                <i 
                                    className={classes.legendI}    
                                    style={{ background: category.color }}>
                                </i> 
                                {category.title}
                                <br />
                            </span>
                        ))}


                    </div>
                </div>
            )}
        </React.Fragment>

    )
}
