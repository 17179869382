import React, { Component } from 'react'
import SearchFormBox from './SearchBoxForm'
import SearchResults from './SearchResults'

export class SearchBox extends Component {
    constructor(props) {
        super(props);

        this.state = { searchResults: ['test'] };
    }
    render() {
        return (
            <div id='searchbox'>
                <SearchFormBox {...this.props} searchFeature={(s) => alert(s)} />
                {this.state.SearchResults && (< SearchResults />)}
            </div>
        )
    }
}

export default SearchBox
