import React, { useState } from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SearchListItem from './SearchListItem'

const useStyles = makeStyles(theme => ({
    root: {
        margin: '10px 10px',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        position: 'relative',
        zIndex: 1000
    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    hidden: {
        display: 'none'
    }
}));



export default function SearchResults(props) {

    const classes = useStyles();

    return (
        <Paper className={!props.searchResults.length ? classes.hidden : classes.root} >
            <List className={classes.list}>
                {props.searchResults.map((data, i, arr) => {
                    if (arr.length - 1 === i) {
                        return (
                            <SearchListItem key={data.key} data={data} zoomToFeature={props.zoomToFeature} />
                        )
                    } else {
                        return (
                            <React.Fragment>
                                <SearchListItem key={data.key} data={data} zoomToFeature={props.zoomToFeature}/>
                                <Divider component="li" />
                            </React.Fragment>
                        )
                    }
                })}
            </List>
        </Paper>
    );
}