import * as React from 'react';
import { Route } from 'react-router';
//import MapCanvas from './components/MapCanvas';
import Map from './components/map/Map';
import FetchData from './components/FetchData';
import SearchBoxForm from './components/searchbox/SearchBoxForm'

import './custom.css'

export default () => (
    <React.Fragment>
        <Route exact path='/' component={Map} />
        <Route path='/search' component={SearchBoxForm} />
        <Route path='/fetch-data/:startDateIndex?' component={FetchData} />
    </React.Fragment>
);
