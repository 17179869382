function getFeatures(geojson, property){

}

function generateCategoryColors(geojson, property) {
    var features = geojson.default.features.map(feature => feature.properties[property])

    var set = Array.from(new Set(features))
    var categoryColors = []
    var featureType = 'unkown'

    if (set.size > 8 ) {
        featureType = 'categorical'
        set.map((cat,i) => {
            categoryColors[cat] = {
                color: pallete[cat],
                title : cat
            }
        })

    } else if (typeof (features[0] = 'number')) {
        featureType = 'range'
        var sorted = set.sort(function(a, b){return a-b})
        this.max = Math.max(sorted)
        var d = Math.ceil(sorted.length / 8)

        console.log();
        

        for (var i = 1; i <= pallete.length; ++i) {
            var min = (i - 1)*d;
            var max = (i - 1)*d + d
            if(i < pallete.length){
                categoryColors[i-1] = {
                    color: pallete[i-1],
                    title: `${sorted[min]} - ${sorted[max]}`,
                    min:sorted[min],
                    max:sorted[max]
                }
            } else{
                categoryColors[i-1] = {
                    color: pallete[i-1],
                    title: `${sorted[(i - 1)*d]}+`,
                    min:sorted[min],
                    max: Infinity
                }
            }
         }
    } else {
        categoryColors['unkown'] = {
            color: pallete[i],
            title: "Unknown"
         }
    }
    return {
        featureType,
        categoryColors
    }
}

function getColor(number, categoryColors){
    var categories = categoryColors.filter(c => (number >= c.min) && (number < c.max))
    return categories[categories.length-1].color;
}

const pallete = ['#FFEDA0','#FED976','#FEB24C','#FD8D3C','#FC4E2A','#E31A1C','#BD0026','#800026']

module.exports = {
    generateCategoryColors: generateCategoryColors,
    getColor
}